import * as React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import LayoutWithSidebar from "../layout/withSidebar";
import Seo from "../components/seo";
import "./blog-post.css";

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark;
  const featuredImage = getImage(post.frontmatter.featuredImage);
  const pageSubtitle = post.frontmatter.date;
  const pageTitle = post.frontmatter.title;
  const { previous, next } = data;

  return (
    <LayoutWithSidebar pageTitle={pageTitle} pageSubtitle={pageSubtitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <div className="card article-card head-card">
          <div className="card-body">
            <header>
              <GatsbyImage image={featuredImage} alt={post.frontmatter.title} />
              <h2 className="post-title" itemProp="headline">
                {post.frontmatter.title}
              </h2>
              <p className="post-date">{post.frontmatter.date}</p>
              <p className="post-description">{post.frontmatter.description}</p>
            </header>
          </div>
        </div>
        <div className="card article-card">
          <div className="card-body">
            <section
              dangerouslySetInnerHTML={{ __html: post.html }}
              itemProp="articleBody"
            />
          </div>
        </div>
      </article>
      <nav className="blog-post-nav">
        <ul className="blog-post-nav-list">
          <li className="blog-post-nav-item">
            {previous && (
              <div className="blog-post-nav-card">
                <GatsbyImage
                  className="blog-post-nav-cover"
                  image={getImage(previous.frontmatter.featuredImage)}
                  alt={previous.frontmatter.title}
                />
                <Link
                  className="blog-post-nav-link is-left"
                  to={previous.fields.slug}
                  rel="previous"
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                  <span>{previous.frontmatter.title}</span>
                </Link>
              </div>
            )}
          </li>
          <li className="blog-post-nav-item">
            {next && (
              <div className="blog-post-nav-card">
                <GatsbyImage
                  className="blog-post-nav-cover"
                  image={getImage(next.frontmatter.featuredImage)}
                  alt={next.frontmatter.title}
                />
                <Link
                  className="blog-post-nav-link is-right"
                  to={next.fields.slug}
                  rel="next"
                >
                  <span>{next.frontmatter.title}</span>
                  <FontAwesomeIcon icon={faAngleRight} />
                </Link>
              </div>
            )}
          </li>
        </ul>
      </nav>
    </LayoutWithSidebar>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              width: 1080
              quality: 100
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        category
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              width: 480
              quality: 80
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              width: 480
              quality: 80
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
